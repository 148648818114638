import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store"

const config = {
    headers: { Authorization: `Bearer ${store.getters.getToken}` }
};

export default {
    async updateItemToProd(item){
        let url = 'https://prod.hokmok.com/editorratioconfig/';
        let response = await axios.put(url, item, config);
        return response.data;
    },
    async updateItemToDevelopment(item){
        let url = 'https://prod.hokmok.com/editorratioconfigfordev/';
        let response = await axios.put(url, item, config);
        return response.data;
    },
    async updateItem(item){
        let url = NetworkUtils.HOST+ '/items/'+ (item.id ?? item._id);
        let response = await axios.put(url, item, config);
        return response.data;
    },
    async postItem(item){
        let url = NetworkUtils.HOST+ '/items';

        let response = await axios.post(url, item, config);
        return response.data;
    },
    async getItems(page=1, colors=null, categories=null) {
        let _limit = 1000;
        let _start = _limit * (page - 1);
        let url = `${NetworkUtils.HOST}/items?_start=${_start}&_limit=${_limit}&_sort=category:ASC`;
        if (colors != null){
            let colorFilter = '';
            colors.forEach(color => {
                colorFilter += `&color=${color}`;
            });
            url = url + colorFilter;
        }
        if (categories != null){
            let catFilter = '';
            categories.forEach(cat => {
                catFilter += `&category=${cat}`;
            });
            url = url + catFilter;
        }

        let response = await axios.get(url, config);
        return response.data;
    },
    async getItemsAtGameLevel(gameLevel, environmentId) {
        if(NetworkUtils.NONE_CONNECT_UGS){
            return [];
        }
        let url = `${NetworkUtils.HOST}/items/gameLevel/${gameLevel}?environmentId=${environmentId}`;
        let response = await axios.get(url, config);
        return response.data;
    },
    async getIconTotalUsed(){
        let url = NetworkUtils.HOST+ '/items/icon_used';
        let response = await axios.get(url, config);
        return response.data;
    },
    async getCountDev(){
        let url = NetworkUtils.HOST+ '/items/count_dev';
        let response = await axios.get(url, config);
        return response.data;
    },
    async getCountRelease(){
        let url = NetworkUtils.HOST+ '/items/count_release';
        let response = await axios.get(url, config);
        return response.data;
    },
    async getEffectTotalUsed(){
        let url = NetworkUtils.HOST+ '/items/effect_used';
        let response = await axios.get(url, config);
        return response.data;
    },

    //update to use group
    async getItemGroups(page=1, colors=null, categories=null) {
        let _limit = 1000;
        let _start = _limit * (page - 1);
        let url = `${NetworkUtils.HOST}/item-groups/item-preview?_start=${_start}&_limit=${_limit}&_sort=category:ASC`;
        if (colors != null){
            let colorFilter = '';
            colors.forEach(color => {
                colorFilter += `&color=${color}`;
            });
            url = url + colorFilter;
        }
        if (categories != null){
            let catFilter = '';
            categories.forEach(cat => {
                catFilter += `&category=${cat}`;
            });
            url = url + catFilter;
        }

        let response = await axios.get(url,config);
        return response.data;
    },
    async updateItemGroups(data){
        let url = NetworkUtils.HOST+ '/item-groups/update-items';
        let response = await axios.post(url, data, config);
        return response.data;
    },
    async getGroupItemByGroupId(groupId){
        let url = `${NetworkUtils.HOST}/item-groups/by-id?id=${groupId}`;
        let response = await axios.get(url, config);
        return response.data;
    },
    async getGroupItemByGroupIds(groupIds){
        let url = `${NetworkUtils.HOST}/item-groups/by-ids`;
        let response = await axios.post(url, groupIds, config);
        return response.data;
    },
}