import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store"

const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {
    async deletePlayerById(playerId, environmentId){
        let url = NetworkUtils.HOST+`/api/ugs/deletePlayerById?playerid=${playerId}&environmentId=${environmentId}`;
        let response = await axios.get(url, config);
        return response.data;
    },

    async deletePlayerByCounter(counter){
        let url = NetworkUtils.HOST+"/api/ugs/deletePlayers?numtodelete="+counter;
        let response = await axios.get(url, config);
        return response.data;
    },

    async deletePlayerByEnvironmentId(environmentId,counter){
        let url = NetworkUtils.HOST+`/api/ugs/deletePlayerByEnvironmentId?environmentId=${environmentId}&numtodelete=${counter}`;
        let response = await axios.get(url, config);
        return response.data;
    },

    async pushAddResource(item, shop, environmentId, isAutoPublish){
        let url = NetworkUtils.HOST+`/api/ugs/economy/shop?environmentId=${environmentId}&isAutoPublish=${isAutoPublish}`;
        let data = {
            item: item,
            shop: shop
        };
        let configPost = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
            data : JSON.stringify(data)
        };
        return axios.request(configPost);
    },

    async pushAddResources(items, shops, environmentId, isAutoPublish){
        let url = NetworkUtils.HOST+`/api/ugs/economy/shops?environmentId=${environmentId}&isAutoPublish=${isAutoPublish}`;
        let data = {
            items: items,
            shops: shops
        };
        let configPost = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
            data : JSON.stringify(data)
        };
        return axios.request(configPost);
    },

    async deleteItemById(itemId, shopId, environmentId, isAutoPublish) {
        let url = NetworkUtils.HOST+`/api/ugs/economy/deleteItem?itemId=${itemId}&shopId=${shopId}&environmentId=${environmentId}&isAutoPublish=${isAutoPublish}`;
        let response = await axios.get(url, config);
        return response.data;
    },

    async hardDeleteItemById(groupId, itemId, shopId, environmentIds, isAutoPublish) {
        let url = NetworkUtils.HOST+`/api/ugs/economy/hardDeleteItem?isAutoPublish=${isAutoPublish}`;
        let dataPush = {
            groupId: groupId,
            itemId: itemId,
            shopId: shopId,
            isAutoPublish: isAutoPublish,
            environmentIds: environmentIds,
        };
        let configPost = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
            data : JSON.stringify(dataPush)
        };
        return axios.request(configPost);
    },

    async hardDeleteItemByIds(dataPush, isAutoPublish, environmentId) {
        console.log("api hard delete by ids was disable sync with UGS");
        let url = NetworkUtils.HOST+`/api/ugs/economy/hardDeleteItems?isAutoPublish=${isAutoPublish}&environmentId=${environmentId}`;
        let configPost = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
            data : JSON.stringify(dataPush)
        };
        return axios.request(configPost);
    },

    async addSmpGem(environmentId){
        let url = NetworkUtils.HOST+"/api/ugs/economy/gem?environmentId="+environmentId;
        let configPost = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: config.headers
        };
        let response = await axios.request(configPost);
        return response.data;
    },

    async addRemoteConfig(config, environmentId){
        let url = NetworkUtils.HOST+"/api/ugs/economy/remoteConfig?environmentId="+environmentId;
        let configPost = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
            data : JSON.stringify(config)
        };
        return axios.request(configPost);
    },

    async updateRemoteConfig(config, key, environmentId){
        let url = NetworkUtils.HOST+`/api/ugs/economy/updateRemoteConfig?key=${key}&environmentId=${environmentId}`;
        let configPost = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
            data : JSON.stringify(config)
        };
        return axios.request(configPost);
    },

    async addLeaderboardConfig(leaderboardConfig, environmentId){
        let url = NetworkUtils.HOST+"/api/ugs/economy/leaderboardConfig?environmentId="+environmentId;
        let configPost = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
            data : JSON.stringify(leaderboardConfig)
        };
        return axios.request(configPost);
    },

    async publishEconomy(environmentId){
        let url = NetworkUtils.HOST+"/api/ugs/economy/publish?environmentId="+environmentId;
        let configPost = {
            method: 'put',
            maxBodyLength: Infinity,
            url: url,
            headers: config.headers,
        };
        let response = await axios.request(configPost);
        return response.data;
    },

    async getItem(environmentId){
        if(NetworkUtils.NONE_CONNECT_UGS){
            return [];
        }
        let url = NetworkUtils.HOST+"/api/ugs/economy/item?environmentId="+environmentId;
        let response = await axios.get(url, config);
        return response.data;
    },

    async cloneResource(sourceEnvironmentId, destEnvironmentId){
        let url = NetworkUtils.HOST+`/api/ugs/economy/cloneResource?sourceEnvironmentId=${sourceEnvironmentId}&destEnvironmentId=${destEnvironmentId}`;
        let response = await axios.get(url, config);
        return response.data;
    },

    async cloneFromPack(pack, destEnvironmentId){
        let url = NetworkUtils.HOST+`/template-item-pack/cloneFromPack?pack=${pack}&destEnvironmentId=${destEnvironmentId}`;
        let response = await axios.get(url, config);
        return response.data;
    },

    async deleteTemplatePack(packName) {
        let url = NetworkUtils.HOST+"/template-item-pack/deletePack?pack="+packName;
        let configDelete = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                Authorization: authorize
            },
        };
        return axios.request(configDelete);
    },

    async deleteLocalEnvironment(environmentId) {
        let url = NetworkUtils.HOST+"/environment-config/deleteEnvironment?environmentId="+environmentId;
        let configDelete = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                Authorization: authorize
            },
        };
        return axios.request(configDelete);
    },

    async clearItemsFromEnvironment(environmentId) {
        let url = NetworkUtils.HOST+"/environment-config/clearItemsFromEnvironment?environmentId="+environmentId;
        let configDelete = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                Authorization: authorize
            },
        };
        return axios.request(configDelete);
    },
}